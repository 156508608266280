<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Digital Signature</h1>
    </div>
    <div class="vap-page-content">
      <div v-show="!showConsentModal">
        <div class="sig-row">
          <div class="image-group" @click="toggleChooseMode('signature')">
            <div v-if="hasUserSignature" class="signature-image-bounds">
              <img class="signature-image" :src="userSignature" alt="Signature">
            </div>
            <div v-else>
              <div class="signature-image-bounds center">
                <VasionAddCircleIcon class="up-20" />
              </div>
            </div>
            <span v-if="hasUserSignature" class="sub-title">EDIT SIGNATURE</span>
            <span v-else class="sub-title">ADD SIGNATURE</span>
          </div>
          <div class="image-group" @click="toggleChooseMode('initials')">
            <div v-if="hasUserInitials" class="initials-image-bounds">
              <img class="initials-image" :src="userInitials" alt="User Signature">
            </div>
            <div v-else>
              <div class="initials-image-bounds center">
                <VasionAddCircleIcon class="up-20" />
              </div>
            </div>
            <span v-if="hasUserInitials" class="sub-title">EDIT INITIALS</span>
            <span v-else class="sub-title">ADD INITIALS</span>
          </div>
        </div>
        <div class="digital-signature-modal-bottom">
            <VasionInput
              v-model="userPassword"
              v-debounce:300ms="savePassword"
              class="digital-signature-modal-bottom-item"
              inputType="top-white"
              title="DIGITAL SIGNATURE PASSWORD"
              placeholder="Enter Password..."
              type="password"
              :width="'396'"
            />
            <VasionCheckbox
              id="apply-timestamp-to-signatures"
              class="digital-signature-modal-bottom-item fix-checkbox"
              name="apply-timestamp-to-signatures"
              :checked="shouldApplyTimestampForUser"
              @change="handleApplyTimestampChange()"
            >
              Apply Timestamp to Your Signatures
            </VasionCheckbox>
        </div>
      </div>
    </div>
    <VasionLegallyBindingConsentModal
      class="consent-modal"
      :documentID="0"
      :sync="showConsentModal"
      @confirmButtonClick="completeSignature"
      @noButtonClick="cancelClick"
    >
    </VasionLegallyBindingConsentModal>
    <md-dialog id="signature-dialog" :md-active.sync="showSignatureModal" :md-click-outside-to-close="false">
      <SaveSignatureOrInitials
          ref="saveSignatureOrInitials"
          :isInitials="version"
          @back-click="toggleChooseMode"
          @cancel-click="closeClick"
          @finish-consent="finishConsent"
          @show-consent="toggleConsentModal"
        />
    </md-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import SaveSignatureOrInitials from '@/components/digitalSignature/SaveSignatureOrInitials.vue'

export default {
  name: 'ProfileDigitalSignature',
  components: {
    Loading,
    SaveSignatureOrInitials,
  },
  data: function () {
    return {
      applyTimestamp: false,
      isInChooseMode: true,
      isInConsent: false,
      isInitials: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      userPassword: '',
      showConsentModal: false,
      signaturePrefix: 'data:image/png;base64,',
      version: 'signature',
    }
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
    hasUserInitials() {
      const initials = this.$store.state.digitalSignature.userInitials
      return initials !== '' && initials.replace(this.signaturePrefix, '') !== ''
    },
    hasUserSignature() {
      const signature = this.$store.state.digitalSignature.userSignature
      return signature !== '' && signature.replace(this.signaturePrefix, '') !== ''
    },
    shouldApplyTimestampForUser() { return this.$store.state.digitalSignature.shouldApplySignatureTimestampForUser },
    showSignatureModal() { return !this.isInChooseMode },
    userInitials() { return `${this.signaturePrefix}${this.$store.state.digitalSignature.userInitials}` },
    userSignature() { return `${this.signaturePrefix}${this.$store.state.digitalSignature.userSignature}` },
  },
  async created() {
    this.$store.dispatch('digitalSignature/getUserSignatureForStore')
    this.$store.dispatch('digitalSignature/getUserInitialsForStore')

    const response = await this.$store.dispatch('digitalSignature/getUserInfo')
    if (response?.HasSignature) {
      this.userPassword = response.SignaturePassword
    }
    await this.$store.dispatch('digitalSignature/shouldApplySignatureTimestamp')
    this.applyTimestamp = this.shouldApplyTimestampForUser
  },
  methods: {
    cancelClick() {
      this.toggleConsentModal()
      this.toggleChooseMode()
    },
    closeClick() {
      this.$emit('closeAddOrEdit')
    },
    completeSignature() {
      this.$refs.saveSignatureOrInitials.okClick()
    },
    finishConsent() {
      this.showConsentModal = false
      this.isInChooseMode = true
    },
    async handleApplyTimestampChange() {
      this.applyTimestamp = !this.applyTimestamp
      await this.$store.dispatch('digitalSignature/saveShouldApplySignatureTimestamp', { Value: this.applyTimestamp ? 'True' : 'False' })
    },
    async savePassword() {
      this.isLoading = true
      const result = await this.$store.dispatch('digitalSignature/saveUserPassword', { signature: this.userSignature.substr(22), password: this.userPassword })
      if (!result || result.Value === 'False') {
        console.warn('Error saving password')
      }
      this.isLoading = false
    },
    toggleChooseMode(value) {
      this.version = value === 'initials'
      this.isInitials = value === 'initials'
      this.isInChooseMode = !this.isInChooseMode
    },
    toggleConsentModal() {
      this.showConsentModal = !this.showConsentModal
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.container {
  padding: 20px;
  margin: 0px;
}

.signature-image-bounds {
  width: 464px;
  min-width: 464px;
  max-width: 464px;
  height: 200px;
  border: 1px solid $grey-100;
  border-radius: 8px;
  margin-right: 8px;
  text-align: center;
}
.signature-image {
  max-height: 170px;
  border-radius: 8px;
}

.initials-image-bounds {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  border: 1px solid $grey-100;
  border-radius: 8px;
  overflow: hidden;
}

.initials-image {
  border-radius: 8px;
  height: 154px;
  width: 154px;
  position: relative;
  left: 15px;
  bottom: -6px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $orange-300;
}

.choose {
  height: 100%;
  width:  100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.sig-row {
  max-width: 880px;
  height: 283px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.image-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;

  :hover {
    background-color: $grey-100;
    cursor: pointer;
  }
}

.choose-header {
  width: 100%;
  font-family: $font-family-bold;
  font-size: 24px;
  color: $grey-500;
  margin: 0px 0 25px 0;
}

.sub-title {
  position: relative;
  top: -40px;
  font-family: $font-family-medium;
  font-size: 14px;
  color: $primary;
}

.up-20 {
  position: relative;
  bottom: 20px;
  fill: $primary;
}

.digital-signature-modal-bottom {
  width: 100%;
  margin: 13px 0;
  display: flex;
  align-items: center;

  .digital-signature-modal-bottom-item {
    margin: 0 16px 5px 0;
  }
}

.auto-left {
  margin-left: auto;
}

.vasion-terms-of-service {
  color: $orange-300 !important;
  text-decoration: underline;
}

.fix-checkbox {
  position: relative;
  top: 10px;
}

.consent-modal {
  z-index: 55;
}
</style>
