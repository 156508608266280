<template>
  <div class="vasion-white-background">
    <div class="main-div">
      <div class="vasion-page-title">
        Add Your
        <span v-if="isInitials">Initials</span>
        <span v-else>Signature</span>
      </div>
      <div class="md-layout w100 tabs">
        <VasionButton id="button-draw" :classProp="drawTabClass" @vasionButtonClicked="updateSelectedTab('draw')">
          Draw
        </VasionButton>
        <VasionButton id="button-add-saved" :classProp="uploadTabClass" @vasionButtonClicked="updateSelectedTab('upload')">
          Upload
        </VasionButton>
      </div>
      <div v-if="selectedTabName === 'draw' && isInitials" id="tab-draw-initials">
        <Draw id="save-initials-draw" ref="initials" />
      </div>
      <div v-if="selectedTabName === 'draw' && !isInitials" id="tab-draw">
        <Draw id="save-sig-draw" ref="signature" />
      </div>
      <div v-if="selectedTabName === 'upload'">
        <file-pond ref="pond" :server="serverOptions" />
      </div>
      <div class="bottom-div">
        {{ errorText }}
        <div>
          <VasionCheckbox
            id="apply-timestamp-to-signatures"
            name="apply-timestamp-to-signatures"
            :checked="shouldApplyTimestampForUser"
            @change="handleApplyTimestampChange()"
          >
            Apply Timestamp to Your Signatures
          </VasionCheckbox>
        </div>
        <div class="auto-left">
          <VasionButton id="button-back" :classProp="'secondary'" @vasionButtonClicked="backClick()">
            BACK
          </VasionButton>
          <VasionButton id="button-sign" :classProp="'primary'" @vasionButtonClicked="showConsent()">
            SAVE
          </VasionButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import vueFilePond from 'vue-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import Draw from '@/components/digitalSignature/Draw.vue'
import { toBase64 } from '@/store/helperModules/storage.module'

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  name: 'SaveSignatureOrInitials',
  components: {
    Draw,
    FilePond,
  },
  props: {
    isInitials: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      applyTimestamp: false,
      errorText: '',
      localSignatureString: '',
      myFiles: [],
      selectedTabName: 'draw',
      serverOptions: {
        process: this.processHandler,
      },
    }
  },
  computed: {
    drawTabClass() {
      return this.selectedTabName === 'draw' ? 'tab-selected' : 'tab'
    },
    shouldApplyTimestampForUser() { return this.$store.state.digitalSignature.shouldApplySignatureTimestampForUser },
    typeTabClass() {
      return this.selectedTabName === 'type' ? 'tab-selected' : 'tab'
    },
    userInitials() { return `data:image/png;base64,${this.$store.state.digitalSignature.userInitials.Value}` },
    userSignature() { return `data:image/png;base64,${this.$store.state.digitalSignature.userSignature.Value}` },
    uploadTabClass() {
      return this.selectedTabName === 'upload' ? 'tab-selected' : 'tab'
    },
  },
  async created() {
    await this.$store.dispatch('digitalSignature/shouldApplySignatureTimestamp')
    this.applyTimestamp = this.shouldApplyTimestampForUser
  },
  methods: {
    backClick() {
      this.$emit('back-click')
    },
    async handleApplyTimestampChange() {
      this.applyTimestamp = !this.applyTimestamp
      await this.$store.dispatch('digitalSignature/saveShouldApplySignatureTimestamp', { Value: this.applyTimestamp ? 'True' : 'False' })
    },
    okClick() {
      let signature
      switch (this.selectedTabName) {
        case 'draw':
          // eslint-disable-next-line
          this.isInitials ? this.saveInitials() : this.saveSignature()
          this.$emit('back-click')
          break;
        case 'upload':
          // eslint-disable-next-line
          this.isInitials ? this.saveUploadInitials() : this.saveUploadSignature()
          this.$emit('back-click')
          break;
        default:
          signature = ''
          break;
      }

      this.$emit('finish-consent')
      this.$emit('ok-click', signature)
    },
    // eslint-disable-next-line
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        progress(true, 0, 1024);
        load(file.name);
        this.localSignatureString = file;

        this.$emit('fileUploaded')
      } catch (e) {
        console.warn(e)
      }

      return {
        abort: () => {
          abort();
        },
      };
    },
    saveInitials() {
      const initials = this.$refs.initials.saveSignature()
      return this.$store.dispatch('digitalSignature/saveInitialsToUser', initials.substr(22))
    },
    saveSignature() {
      const signature = this.$refs.signature.saveSignature()
      return this.$store.dispatch('digitalSignature/saveSignatureToUser', signature.substr(22))
    },
    async saveUploadInitials() {
      const initials = await toBase64(this.localSignatureString)
      return this.$store.dispatch('digitalSignature/saveInitialsToUser', initials.substr(22))
    },
    async saveUploadSignature() {
      const signature = await toBase64(this.localSignatureString)
      return this.$store.dispatch('digitalSignature/saveSignatureToUser', signature.substr(22))
    },
    showConsent() {
      this.$emit('show-consent')
    },
    updateLocalSignatureString(string) {
      this.localSignatureString = string
    },
    updateSelectedTab(tabName) {
      this.selectedTabName = tabName
    },
  },
}
</script>

<style lang="scss" scoped>
.main-div {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.bottom-div {
  display: flex;
  padding-top: 16px;
  align-items: center;
}

.auto-left {
  margin-left: auto;
}

.tabs {
  margin-bottom: 20px;
}

#tab-draw-initials {
  width: 200px;
  margin: auto;
}
</style>
